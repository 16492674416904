<template>
	<div class='goal-info'>
        <img class='goal-logo' :src='goal.image' v-if="goal.image && goal.image !== null" />
        <h3 class='number'>{{ this.label }}</h3>
        <p class='label' v-html='goal.label_long'></p>
    </div>
</template>

<script>
import { getLabel } from './../../lib/common';

export default {
	props: {
		goal: {
			type: Object,
			default: null
		}
	},
	computed: {
		label(){
			return getLabel( this.goal, {number: 'number', label: 'label_short' } )
		}
	}
}
</script>

<style lang='scss' scoped>
.goal-info{
    position: absolute;
    top: 70px;
    left: 10px;
    width: 290px;
    padding: 10px;
    background-color: #FEFEFE;
    border: solid 1px #DEDEDE;
    border-radius: 5px;

    .goal-logo{
        width: 50px;
        height: 50px;
        position: absolute;
        top: 10px;
        left: 10px;
        border-radius: 5px;
    }

    .label{
        text-align: center;
        font-size: 13px;
    }
}
.goal-info{
    .number{
        margin-left: 70px;
        width: calc( 100% - 70px );
        text-align: left;
    }
}
</style>