<template>
	<div class='mobile-matrix'>
		<slot name="before"></slot>
		
		<RowContainer v-for='goal in goals'
			:key='goal.id'
			:goal='goal'
		>
			<template #interactions>
				<InteractionContainer v-for='interaction in interactions( goal )'
					:key='interaction.id'
					:interaction='interaction'
					:score='getScore( goal, interaction )'
					:restricted='!isRestricted( goal, interaction )'
					@click='handleClick( goal, interaction )'
				/>
			</template>

			<template v-slot:overlay="{showIndicator, interactionsVisible, toggleInteractions}">
				<transition name="fade">
					<div class='floating top right' v-if='interactionsVisible && showIndicator && !answering'>
						<div class='answering-indicator'>
							<p class="number">{{ goal.number }}</p>
							<img :src='goal.image' class="mobile-indicator"/>
							<a @click='toggleInteractions' class="toggle interactions">
								<i class='fas fa-caret-square-up'></i>
								{{ $t( 'matrix.hide interactions') }}
							</a>
						</div>
					</div>
				</transition>
			</template>
		</RowContainer>

		<div class='modal-backdrop' v-if='answering !== undefined'>
			<slot name="modal" 
				:answering='answering'
				:event='event'
				:close-modal='closeModal'
			>
			</slot>
		</div>
		<slot name="after" :answering='answering'></slot>
	</div>
</template>

<script>
import RowContainer from './RowContainer.vue';
import InteractionContainer from './InteractionContainer.vue';
import AnsweringModal from './../AnsweringModal.vue';

export default {
	components: {
		RowContainer,
		InteractionContainer,
		AnsweringModal
	},
	data: function(){
		return {
			answering: undefined,
		}
	},
	props: {
		event: {
			type: Number,
			required: true
		},
		goals: {
			type: Array,
			required: true
		},
		answers: {
			type: Array,
			default: () => []
		},
		restrictions: {
			type: Array,
			default: () => []
		},
		userType: {
			type: String,
            default: "respondent"
        },
	},
	methods: {
		getGoal( goal_id ){
			const idx = this.goals.findIndex( g => g.goal_id == goal_id );
			if( idx > -1 ){
				return this.goals[idx]
			}
			return undefined
		},
		interactions( goal ){
			return this.goals.filter( g => g.goal_id !== goal.goal_id );
		},
		getAnswer( goal_id, interaction_id ){
			let idx = this.answers.findIndex( a => {
				return a.goal_id === goal_id && a.interaction_id === interaction_id 
			} )
			if( idx > -1 ){
				return this.answers[ idx ]
			}
			return undefined
		},
		getScore( goal, interaction ){
			let answer = this.getAnswer( goal.goal_id, interaction.goal_id )

			if( answer !== undefined ){
				return answer.score
			}

			return undefined
		},
		isRestricted( goal, interaction ){
			if( this.restrictions.length < 1 ){
				return false
			}
			
			let idx = this.restrictions.findIndex( r => {
				return r.goal_id === goal.goal_id && r.interaction_id === interaction.goal_id
			} )
			return idx > -1
		},
		handleClick( goal, interaction ){
			const answer = this.getAnswer( goal.goal_id, interaction.goal_id )

			if( answer === undefined){
				this.answering = {
					goal,
					interaction,
					score: undefined,
					comment: undefined,
					uncertain: 0
				}
			} else {
				answer.goal = goal
				answer.interaction = interaction
				this.answering = answer
			}
		},
		closeModal(){
			this.answering = undefined
		}
	}
}
</script>

<style lang='scss'>
.mobile-matrix{
	.goal-card{
		margin-bottom: 10px;
	}
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.4s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>