<template>
    <g :class="['matrix-answer', restricted ? 'restricted' : 'open']" @click='clicked'
        v-if='goal.id !== relation.id'
        :transform="'translate(' + x + ',' + y +')'"    
    >
        <circle v-if='data.score !== null'
            class='given-answer'
            cx="0"
            cy="0"
            :r="radiusScale( data.score )"
            :fill="colorScale(data.score)"
            :fill-opacity="opacity"
        ></circle>
        
        <g class='empty-answer' v-else>
            <circle
                cx="0"
                cy="0"
                :r="radiusScale()-4"
            ></circle>

            <text class='question-mark'
                x="0"
                y="2"
                dominant-baseline="middle"
                text-anchor="middle"
                :font-size='radiusScale() + "px"'
            >
                ?
            </text>
        </g>
    </g>
</template>

<script>
export default {
    props: {
        colorScale: {
            type: Function,
            required: true
        },
        radiusScale: {
            type: Function,
            required: true
        },
        goal: {
            type: Object,
            required: true
        },
        relation: {
            type: Object,
            required: true
        },
        x :{
            type: Number,
            required: true
        },
        y: {
            type: Number,
            required: true
        },
        data: {
            type: Object,
            required: true
        },
        opacity: {
            type: Number,
            default: 1
        },
        restricted: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        clicked(){
            if( this.restricted ){
                return;
            }
            this.$emit("interaction-click", this.data)
        }
    }
}
</script>

<style>
.empty-answer circle{
    fill: #EFEFEF;
    stroke: #CECECE;
}

.restricted .empty-answer{
    opacity: 0.2;
}

.empty-answer .question-mark{
    font-family: "Open Sans";
    fill: #808080;
    user-select: none;
}
</style>