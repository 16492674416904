<template>
    <div class='login-form respondent'>
        <div class='header'>
            <h1 class='welcome'>
                {{welcomeMessage}}
            </h1>
        </div>

        <div class='login-container'>
            <transition name="fade" mode="out-in">
                <div class="dialog" v-if='step === 0' key="1">
                
                    <div class="inputs">
                        <label>
                            {{organizationLabel}}
                        </label>
                        <div class="dropdown-filter">
                            <v-select
                                :options='m_organizations'
                                label='name'
                                v-model='organization'
                            ></v-select>
                        </div>

                        <p class='error form' v-if='hasError'>
                            {{errorMessage}}
                        </p>
                    </div>
                            
                    <div class='pager-buttons'>
                        <ajax-button
                            class='page-button next'
                            :request="{
                                method: 'GET',
                                url: '/events/get',
                                params: {
                                    organization_id : organization ? organization.id : null
                                }    
                            }"
                            :validation-function="validateOrganization.bind( this )"
                            @success="($event) => handleOrganizationSuccess($event, stepUp )"
                            @error="handleError"
                            @validation-error="handleError"
                        >
                            {{nextLabel}}
                            <i class='fas fa-caret-right'></i>
                        </ajax-button>
                    </div>
                </div>
            </transition>
            <transition name="fade" mode="out-in">
                <div class="dialog" v-if="step === 1" key="2">
                    <div class="inputs">
                        <label>
                            {{eventLabel}}
                        </label>

                        <div class="dropdown-filter">
                            <v-select
                                :options='m_events'
                                label='name'
                                v-model='event'
                            ></v-select>
                        </div>

                        <p class='error form' v-if='hasError'>
                            {{errorMessage}}
                        </p>
                    </div>

                    <div class='pager-buttons'>
                        <span class='page-button previous' 
                            @click='stepDown' v-if='startPage < 1'>
                             <i class='fas fa-caret-left'></i>
                            {{previousLabel}}
                        </span>
                        <ajax-button
                            class='page-button next'
                            :request="{
                                method: 'GET',
                                url: '/events/get',
                                params: {
                                    organization_id : organization ? organization.id : null, 
                                    id : event ? event.id : null
                                }
                            }"
                            :validation-function="validateEvent.bind(this)"
                            @success="($event) => handleEventSuccess($event, stepUp )"
                            @error="handleError"
                            @validation-error="handleError"
                        >
                            {{nextLabel}}
                            <i class='fas fa-caret-right'></i>
                        </ajax-button>
                    </div>
                </div>
            </transition>
            <transition name="fade" mode="out-in">
                <div class='dialog' v-if="step === 2" key="3">
                    <div class="inputs">
                        <label>
                            {{tokenLabel}}
                        </label>

                        <input v-model='token' type='text' class='token' />

                        <p class='error form' v-if='hasError'>
                            {{errorMessage}}
                        </p>
                    </div>

                    <div class='pager-buttons'>
                        <span class='page-button previous' 
                            v-if='startPage < 2'
                            @click='stepDown'>
                             <i class='fas fa-caret-left'></i>
                            {{previousLabel}}
                        </span>
                        <ajax-button
                            class='page-button next'
                            :request="{
                                method: 'POST',
                                url: '/login',
                                data: {
                                    event_id : event ? event.id : null,
                                    token: token
                                }
                            }"
                            :validation-function="validateToken.bind(this)"
                            @success="($event) => handleTokenSuccess( $event, redirect )"
                            @error="handleError"
                            @validation-error="handleError"
                        >
                            {{loginLabel}}
                        </ajax-button>
                    </div>
                </div>
            </transition>
        </div>
        <slot name='below'></slot>
    </div>
</template>

<script>
import 'vue-select/dist/vue-select.css';
export default {
    components : {
        "v-select" : require('vue-select'),
        "ajax-button" : require('./../buttons/AjaxButton.vue').default
    },
    data: function(){
        return {
            organization: this.inputOrganization,
            event: null,
            token: null,
            m_organizations: this.organizations,
            m_events: this.events,
            hasError: false,
            errorMessage: null,
            step: this.startPage || 0
        }
    },
    props: {
        welcomeMessage: {
            type: String,
            default: "Welcome"
        },
        organizationLabel: {
            type: String,
            default: "Select an organization"
        },
        eventLabel: {
            type: String,
            default: "Select an event"
        },
        tokenLabel: {
            type: String,
            default: "Please write your token"
        },
        nextLabel: {
            type: String,
            default: "Next"
        },
        previousLabel: {
            type: String,
            default: "Previous"
        },
        loginLabel : {
            type: String,
            default: "Login"
        },
        organizations: {
            type: Array,
            default: () => {
                return []
            }
        },
        events : {
            type: Array,
            default: () => {
                return []
            }
        },
        startPage: {
            type: Number,
            default: 0
        },
        inputOrganization: {
            type: Object,
            default: null
        },
        organizationValidationMessage: {
            type: String,
            default: "Organization is required"
        },
        eventValidationMessage: {
            type: String,
            default: "Event is required"
        },
        tokenValidationMessage:{
            type: String,
            default: "Token is required"
        }
    },
    methods: {
        validateOrganization(){
            return {
                "result" : this.organization !== null,
                "message" : this.organizationValidationMessage
            }
        },
        validateEvent( callback ){
            return {
                "result" : this.event !== null,
                "message" : this.eventValidationMessage
            }

        },
        validateToken( callback ){
            return {
                "result" : this.token !== null && this.token !== "",
                "message" : this.tokenValidationMessage
            }

        },
        handleOrganizationSuccess( values , callback){
            this.m_events = values
            
            this.hasError = false
            if(callback && "function" === typeof callback){
                callback()
            }
        },
        handleEventSuccess(values, callback){
            this.event = values[0]
            this.hasError = false
            
            if(callback && "function" === typeof callback){
                callback()
            }
        },
        handleTokenSuccess(values, callback){
            
            if(callback && "function" === typeof callback){
                callback()
            }
        },
        redirect(){
            window.location.replace("/matrix/" + this.organization.slug + "/" + this.event.id + "/")
        },
        handleError( message ){
            this.hasError = true
            this.errorMessage = message
        },
        stepUp(){
            this.step += 1
        },
        stepDown(){
            this.hasError = false
            this.step -= 1
        }
    }
}
</script>

<style lang='scss' scoped>

</style>