import { sum } from 'd3-array'
import { adjacencyMatrix, secondOrderOut, secondOrderIn } from '../../lib/network'

function roundScore( score ){
    if(score >= 0){
        return Math.round(score)
    } else {
        return -1 * Math.round(Math.abs(score))
    }
}

export function comparisonRowSum( goal, answers, filter ){
    let goalAnswers = answers.filter(a => {
        return a.goal_id === goal.id
    } )

    if(goalAnswers.length < 1){
        return null
    }

    if(filter){
        if(filter === "positive"){
            goalAnswers = goalAnswers.filter(g => {return g.score > 0})
        }
        if(filter === "negative"){
            goalAnswers = goalAnswers.filter(g => {return g.score < 0})
        }
    }

    if(goalAnswers.length < 1){
        return null
    }

    let total = sum(goalAnswers, g => {
        return roundScore(g.score)
    })

    return total
}

export function comparisonColSum( goal, answers, filter ){
    let interactionAnswers = answers.filter(a => {
        return a.interaction_id === goal.id
    })

    if(interactionAnswers.length < 1){
        return null
    }

    if(filter){
        if(filter === "positive"){
            interactionAnswers = interactionAnswers.filter(g => {return g.score > 0})
        }
        if(filter === "negative"){
            interactionAnswers = interactionAnswers.filter(g => {return g.score < 0})
        }
    }

    if(interactionAnswers.length < 1){
        return null
    }

    return sum(interactionAnswers, i => {return roundScore(i.score)})
}

export function rowSum( goal, answers, filter ){
    let goalAnswers = answers.filter(a => {
        return a.goal_id === goal.goal_id && a.goal_administrative_level === goal.administrative_level
    } )

    if(goalAnswers.length < 1){
        return null
    }

    if(filter){
        if(filter === "positive"){
            goalAnswers = goalAnswers.filter(g => {return g.score > 0})
        }
        if(filter === "negative"){
            goalAnswers = goalAnswers.filter(g => {return g.score < 0})
        }
    }

    if(goalAnswers.length < 1){
        return null
    }

    let total = sum(goalAnswers, g => {
        return roundScore(g.score)
    })

    return total
}

export function colSum( goal, answers, filter){
    let interactionAnswers = answers.filter(a => {
        return a.interaction_id === goal.goal_id
    })

    if(interactionAnswers.length < 1){
        return null
    }

    if(filter){
        if(filter === "positive"){
            interactionAnswers = interactionAnswers.filter(g => {return g.score > 0})
        }
        if(filter === "negative"){
            interactionAnswers = interactionAnswers.filter(g => {return g.score < 0})
        }
    }

    if(interactionAnswers.length < 1){
        return null
    }

    return sum(interactionAnswers, i => {return roundScore(i.score)})
}

export function secondOrderOutDegrees(goals, answers, weight ){
    let M = adjacencyMatrix(goals, answers, "goal_id", "score", "goal_id", "interaction_id")
    let w = weight || 0.5

    return goals.map((g, i) => {
        return {
            "goal_id" : g.goal_id,
            "number" : Math.round(secondOrderOut(M, i, w)*10)/10
        }
    })
}

export function secondOrderInDegrees(goals, answers, weight){
    let M = adjacencyMatrix(goals, answers, "goal_id", "score", "goal_id", "interaction_id")
    
    let w = weight || 0.5

    return goals.map((g, i) => {

        return {
            "goal_id" : g.goal_id,
            "number" : Math.round(secondOrderIn(M, i, w)*10)/10
        }
    })
}


export function rowSums( goals, answers, filter){
    let sums = []

    goals.forEach(goal => {
        sums.push(
            {
                "goal_id" : goal.goal_id,
                "number" : rowSum(goal, answers, filter)
            }
        )
    })

    return sums
}

export function colSums( goals, answers, filter){
    let sums = []

    goals.forEach(goal => {
        sums.push(
            {
                "goal_id" : goal.goal_id,
                "number" : colSum(goal, answers, filter)
            }
        )
    })

    return sums
}

export function sortBy( key, direction ){
    return function(a, b){
        if(a[key] === undefined || b[key] === undefined){
            return 0
        }
        if(a[key] == b[key]){
            return 0
        }

        let dir = direction || "DESC"

        if( dir === "DESC" ){
            return a[key] > b[key] ? -1 : 1
        } else {
            return a[key] < b[key] ? -1 : 1
        }
        
    }
}

export function sortByRowSum( goals, answers, filter ){
    return goals.sort((a, b) => {
        if(rowSum(a, answers, filter) == rowSum(b, answers, filter)){
            return 0
        }

        return rowSum(a, answers, filter) > rowSum(b, answers, filter) ? -1 : 1
    })
}

export function sortByColSum( goals, answers, filter ){
    return goals.sort((a, b) => {       
        if(colSum(a, answers, filter) == colSum(b, answers, filter)){
            return 0
        }

        return colSum(a, answers, filter) > colSum(b, answers, filter) ? -1 : 1
    })
}

export function sortBySecondOrderOut( goals, answers, weight ){
    let M = adjacencyMatrix(goals, answers, "goal_id", "score", "goal_id", "interaction_id")
    let w = weight || 0.5

    return goals.slice().sort((a, b) => {
        let aIndex = goals.findIndex(g => g["goal_id"] === a["goal_id"])
        let bIndex = goals.findIndex(g => g["goal_id"] === b["goal_id"])
        
        if(secondOrderOut(M, aIndex, w) === secondOrderOut(M, bIndex, w)){
            return 0
        }

        return secondOrderOut(M, aIndex, w) > secondOrderOut(M, bIndex, w) ? -1 : 1
    })
}

export function sortBySecondOrderIn( goals, answers, weight ){
    let M = adjacencyMatrix(goals, answers, "goal_id", "score", "goal_id", "interaction_id")
    let w = weight || 0.5

    return goals.slice().sort((a, b) => {
        let aIndex = goals.findIndex(g => g["goal_id"] === a["goal_id"])
        let bIndex = goals.findIndex(g => g["goal_id"] === b["goal_id"])

        if(secondOrderIn(M, aIndex, w) === secondOrderIn(M, bIndex, w)){
            return 0
        }

        return secondOrderIn(M, aIndex, w) > secondOrderIn(M, bIndex, w) ? -1 : 1
    })
}