<template>
    <span class='ajax-button' @click='handleClick'>
        <slot v-if='!isRequesting'>
            
        </slot>
        <spinner v-if='isRequesting'></spinner>
    </span>
</template>

<script>
import axios from 'axios';

export default {
    components: {
        spinner: require('./../spinners/DotSpinner.vue').default
    },
    data: function(){
        return {
            isRequesting: false
        }
    },
    props: {
        request: {
            type: Object,
            default: null
        },
        validationFunction: {
            type: Function,
            default: null
        }
    },
    methods: {
        handleClick: function(){
            if(this.request === null){
                return
            }
            
            if(this.validationFunction !== null && !this.validationFunction().result){
                this.$emit("error", this.validationFunction().message)
                return
            }

            this.isRequesting = true
            axios(this.request)
                .then(res => {
                    if(res.data.success){
                        this.$emit("success", res.data.values)
                    } else{
                        throw Error(res.data.message)
                    }
                })
                .catch(err => {
                    this.$emit("error", err.message)
                })
                .finally(() => {
                    this.isRequesting = false
                })
        }
    }
}
</script>

<style>

</style>