import {scaleQuantize} from 'd3-scale'

/**
 * 
 */
export const scaleColors = [
    "#820D06",
    "#DE0000",
    "#F29F01",
    "#F2F2F2",
    "#A6DEEB",
    "#1AAACC",
    "#014F63"
]
/**
 * 
 */
export const colorScale = scaleQuantize()
    .domain([-3.49999, 3.49999])
    .range( scaleColors );

/**
 * 
 */
export const diatonicColors = [
    "#BF0028",
    "#155480"
]

export const getLabel = function( item, vars ){
    if( vars === undefined ){
        vars = {}
    }

    const n = vars.number || 'number';
    const l = vars.label || 'label';

    const parts = [ item[ n ] ];

    if( item[ l ] ){
        parts.push( item[ l ] )
    }
    
    return parts.join( " - ")
}