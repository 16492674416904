<template>
    <svg :width='width' :height='height' id='matrix' :viewBox="'0 0 ' + width + ' ' + height">
        <g :transform="'translate(' + margin.left + ',' + margin.top +')'" id='main-group'>
            <matrix-grid
                :width='canvasWidth'
                :height='canvasHeight'
                :rowScale='rowScale'
                :colScale='colScale'
            />
            
            <g class='answers'>

                <interaction v-for='interaction in interactions'
                    :key='interaction.id'
                    :colorScale='colorScale'
                    :radiusScale='radiusScale'
                    :x='interaction.x'
                    :y='interaction.y'
                    :goal='interaction.goal'
                    :relation='interaction.relation'
                    :data='interaction'
                    :opacity='interaction.opacity'
                             :restricted="isRestricted(interaction.goal, interaction.relation)"
                    @interaction-click='handleInteractionClick'

                />

            </g>

            <g class='matrix-labels'>
                <g class='row-labels left' v-if="showLeftRowLabels">
                    <text v-for='row in rows'
                        class='hover-info'
                        :key='row.id'
                        x="-5"
                        :y="rowScale(row.id)"
                        dominant-baseline="middle"
                          text-anchor="end"
                        @mouseover='handleLabelHoverIn( $event, row)'
                    >
                        <tspan x="-5">{{row.number}}</tspan>
                        <tspan x="-5" dy="14" font-size="10" v-if="row.administrative_level_name !== null">{{shortenLabel(row.administrative_level_name)}}</tspan>
                    </text>
                </g>

                <g class='col-labels top' v-if="showTopColumnLabels">
                    <text v-for='col in cols'
                        class='hover-info'
                        :key='col.id'
                        :x="colScale(col.id)"
                        y="-10"
                        :transform="'rotate(-45, ' + colScale(col.id) + ' ' + '-10)'"
                        @mouseover='handleLabelHoverIn( $event, col)'
                    >
                        <tspan>{{col.number}}</tspan>
                        <tspan :x="colScale( col.id )" dy="14" font-size="10" v-if="col.administrative_level_name !== null">{{shortenLabel(col.administrative_level_name)}}</tspan>
                    </text>
                </g>

                <g class='row-labels right' v-if="showRightRowLabels">
                    <text v-for='(row) in rows'
                        :class='{stat: rowStats !== "name", "hover-info": rowStats === "name"}'
                        :key='row.id'
                        :y="rowScale(row.id)"
                        :x="canvasWidth + 5"
                        dominant-baseline="middle"
                        @mouseover='handleLabelHoverIn( $event, row)'
                    >
                        {{getLabel(row, "row")}}
                    </text>
                </g>

                <g class='col-labels bottom' v-if="showBottomColumnLabels">
                    <text v-for='(col) in cols'
                        :class='{stat: colStats !== "name", "hover-info": colStats === "name"}'
                        :key='col.id'
                        :x="colScale(col.id)"
                        :y="canvasHeight + 5"
                        :transform="colStats === 'name' ? 'rotate(45, ' + colScale(col.id) + ' ' + (canvasHeight + 5) + ')' : ''"
                        dominant-baseline="hanging"
                        @mouseover='handleLabelHoverIn( $event, col)'
                    >
                        {{getLabel(col, "col" )}}
                    </text>
                </g>
            </g>
        </g>

        
    </svg>
</template>

<script>
import {scalePoint, scaleLinear} from 'd3-scale'
import { arrayContains } from './../../lib/shnt-selection-store/ArrayHelpers';
import MakeSortable from './../../mixins/Matrix/MakeSortable';
import {adjacencyMatrix, secondOrderIn, secondOrderOut} from "../../lib/network";
import {colSum, rowSum} from "./SummaryStatistics";

export default {
    components: {
        'matrix-grid' : require('./MatrixGrid.vue').default,
        'interaction' : require('./Interaction.vue').default
    },
    mixins: [
        MakeSortable,
    ],
    data: function(){
        return {}
    },
    props: {
        rows: {
            type: Array,
            default: () => []
        },
        cols: {
            type: Array,
            default: () => []
        },
        answers : {
            type: Array,
            default: () => []
        },
        restrictions: {
            type: Array,
            default: () => []
        },
        width: {
            type: Number,
            default: 900
        },
        height: {
            type: Number,
            default: 900
        },
        margin: {
            type: Object,
            default: () => {
                return {
                    top: 80,
                    left: 80,
                    right: 80,
                    bottom: 80
                }
            }
        },
        colorScale: {
            type: Function,
            required: true
        },
        minRadius: {
            type: Number,
            default: 5
        },
        maxRadius: {
            type: Number,
            default: 30
        },
        rowStats: {
            type: String,
            default: "name"
        },
        colStats: {
            type: String,
            default: "name"
        },
        uncertainty: {
            type: Array,
            default: () => [0, 100]
        },
        scoreRange: {
            type: Array,
            default: () => [0, 6]
        },
        interactionSelection: {
            type: Array,
            default: () => []
        },
        showTopColumnLabels: {
            type: Boolean,
            default: true
        },
        showBottomColumnLabels: {
            type: Boolean,
            default: true
        },
        showLeftRowLabels: {
            type: Boolean,
            default: true
        },
        showRightRowLabels: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        canvasWidth(){
            return this.width - this.margin.left - this.margin.right
        },
        canvasHeight(){
            return this.height - this.margin.top - this.margin.bottom
        },
        rowScale(){
            return scalePoint()
                .domain(this.sortedRows.map(r => r.id))
                .range([0, this.canvasHeight])
                .padding(0.5)
                .round(true)
        },
        colScale(){
            return scalePoint()
                .domain(this.sortedCols.map(c => c.id))
                .range([0, this.canvasWidth])
                .padding(0.5)
                .round(true)
        },
        radius(){
            let fromScale = Math.min(this.colScale.step()/2 - 1, this.rowScale.step()/2 - 1)
            return Math.min(this.maxRadius, Math.max(fromScale, this.minRadius))
        },
        radiusScale(){
            return () => { return this.radius }
            // return scaleLinear()
            //     .domain([-3, 0, 3])
            //     .range([this.radius, 0.3*this.radius, this.radius])
        },
        interactions(){
            const interactions = []
            for( var i = 0; i < this.rows.length; i++ ){
                for( var j = 0; j < this.cols.length; j++ ){
                    const row = this.rows[ i ];
                    const col = this.cols[ j ];
                    
                    let interaction = this.getAnswer( row, col )
                    interaction.goal = row;
                    interaction.relation = col;
                    interaction.opacity = this.getOpacity( interaction )
                    interaction.x = this.colScale( col.id );
                    interaction.y = this.rowScale( row.id );
                    interactions.push( interaction )
                }
            }
            return interactions
        },
        aMatrix(){
            return adjacencyMatrix(this.rows, this.answers, "goal_id", "score", "goal_id", "interaction_id")
        }
    },
    methods: {
        getAnswer(goal, relation){
            let idx = this.answers.findIndex(a => {
                return a.goal_id === goal.goal_id && a.interaction_id === relation.goal_id
                    && a.goal_administrative_level === goal.administrative_level
                    && a.interaction_administrative_level === relation.administrative_level
            })

            if(idx < 0){
                return {
                    goal: goal,
                    relation: relation,
                    score: null,
                    comment: null,
                    uncertain: 0
                }
            }
            return this.answers[idx]
        },
        getSummaryStatistic( key, goal, direction ){
            let value = null;

            switch( key ) {
                case "outDegree":
                    value = rowSum(goal, this.answers);
                    break;
                case "positiveOutDegree":
                    value = rowSum(goal, this.answers, "positive");
                    break;
                case "negativeOutDegree":
                    value = rowSum(goal, this.answers, "negative");
                    break;
                case "inDegree":
                    value = colSum(goal, this.answers);
                    break;
                case "positiveInDegree":
                    value = colSum(goal, this.answers, "positive");
                    break;
                case "negativeInDegree":
                    value = colSum(goal, this.answers, "negative");
                    break;
                case "secondOrderOut":
                    if (direction === "row") {
                        let idx = this.rows.findIndex(c => c.id === goal.id)
                        value = secondOrderOut(this.aMatrix, idx, 1);
                    } else {
                        let idx = this.cols.findIndex(c => c.id === goal.id)
                        value = secondOrderOut(this.aMatrix, idx, 1);
                    }
                    if (value === 0) {
                        value = null
                    }
                    break;
                case "secondOrderIn":
                    if (direction === "row") {
                        let idx = this.rows.findIndex(c => c.id === goal.id)
                        value = secondOrderIn(this.aMatrix, idx, 1);
                    } else {
                        let idx = this.cols.findIndex(c => c.id === goal.id)
                        value = secondOrderIn(this.aMatrix, idx, 1);
                    }
                    if (value === 0) {
                        value = null
                    }
                    break;
            }
            return value;
        },
        getLabel( goal, direction ){
            let labelKey = direction === "row" ? this.rowStats : this.colStats
            if( !labelKey ){
                labelKey = "name"
            }
            if( labelKey === "name" ){
                return goal[labelKey];
            }

            let value = this.getSummaryStatistic( labelKey, goal, direction );

            // Return empty with dash label
            if( !value ){
                return "-"
            }

            if(Number(value) === value && value % 1 !== 0){
                return Math.round(goal[labelKey] * 10)/10
            }

            return value;
        },
        handleInteractionClick( data ){
            this.$emit("interaction-click", data )
        },
        handleLabelHoverIn( event, data){
            if(event.currentTarget.classList.contains("hover-info")){
                this.$emit("goal-hover", data)
            }
        },
        getOpacity( answer ){
            let op = 1
            
            // Check the uncertainty level
            if(answer.uncertain < this.uncertainty[0]/100){
                op = 0.1
            }
            if(answer.uncertain > this.uncertainty[1]/100){
                op = 0.1
            }

            // Check the range filter
            if(answer.min_score < this.scoreRange[0] || answer.max_score > this.scoreRange[1]){
                op = 0.1
            }

            // Check selection latest so it overrides any previous settings
            if( this.interactionSelection.length > 0 ){
                if( !arrayContains( this.interactionSelection, [answer.goal.goal_id, answer.relation.goal_id]) ){
                    op = 0.1
                } else {
                    op = 1
                }
            }

            return op
        },
        shortenLabel( text ){
            const wordLimit = 1
            const characterLimit = 20

            let parts = text.split( " " );
            if( parts.length > wordLimit ){
                let deduction = 0
                // If the word limit still leads to strings that are too long, deduct from the word limit
                while( parts.slice(0, (wordLimit - deduction) ).join( " " ).length > (characterLimit - 3 ) ){
                    deduction += 1
                }
                return parts.slice(0, (wordLimit - deduction) ).join( " " ) + "..."
            }
            return text
        },
        isRestricted( goal, interaction ){
            if( this.restrictions.length ){
                let idx = this.restrictions.findIndex( r => {
                    return r.goal_id === goal.goal_id &&
                        r.interaction_id === interaction.goal_id &&
                        r.goal_administrative_level === goal.administrative_level &&
                        r.interaction_administrative_level === interaction.administrative_level
                } )
                return idx < 0
            }
            return false;
        }
    },
}
</script>

<style>
    .row-labels.right,
    .col-labels.bottom{
        font-weight: bold
    }
</style>