<template>
    <div class='switch-container'>
        <span class='off-label'>
            {{labels.off}}
        </span>
        <div :class="['switch', {'on': on===1}]" @click.stop='toggle'>
            <div 
                :class="['button', {'on': on === 1}]"
            >
            </div>
        </div>
        <span>
            {{labels.on}}
        </span>
    </div>
</template>

<script>
export default {
    data: function(){
        return {
            on: this.value
        }
    },
    props: {
        labels: {
            type: Object,
            default: () => {
                return {
                    off : "Off",
                    on : "On"
                }
            }
        },
        value: {
            type: Number,
            default: 0
        },
        readOnly: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        toggle(){
            if(this.readOnly){
                return
            }
            this.on = this.on === 0 ? 1 : 0
            this.$emit("toggled", this.on)
            this.$emit("input", this.on)
        }
    },
    watch: {
        value(){
            if(!this.readOnly){
                return
            }
            this.on = this.value
        }
    }
    
}
</script>

<style lang='scss' scoped>
    .switch-container{
        display: flex;
        align-items: center;
        justify-content: center;

        .switch{
            cursor: pointer;
            overflow: hidden;
            position: relative;
            width: 60px;
            height: 30px;
            border-radius: 15px / 50%;
            transition: border-color 0.4s, background-color 0.4s;
            margin: 0 10px;
            box-shadow: inset 0px 0px 5px 3px #FFFFFF;

            .button{
                position: absolute;
                top: 1px;
                left: 2px;
                height: 26px;
                width: 26px;
                border-radius: 50%;
                transition: left 0.4s, background-color 0.4s;
                box-shadow: inset 0px 0px 10px 0px rgba(0,0,0,0.2);
                padding: 0;

                &.on{
                    left: 30px;
                }
            }
        }
    }
    
</style>