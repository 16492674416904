<template>
	<div :class="['interaction-card', { restricted }]">
		<div class='info'>
			<div class='header'>
				<img :src='interaction.image' :alt='"interaction " + interaction.number' />
				<h4>
					{{ label }}
				</h4>
			</div>
			<div class='contained-box'>
				<p>{{ interaction.label_long }}</p>
			</div>
		</div>
		<div :class="['scoring', {empty: score === undefined} ]" :style='scoreStyle' @click='handleClick'>
			<span class="score empty" v-if='score === undefined'>
				?
			</span>
			<span v-else class="score">
				{{ score }}
			</span>
		</div>
	</div>
</template>

<script>
import { colorScale } from './../../../lib/common';

export default {
	props: {
		interaction: {
			type: Object,
			required: true
		},
		score: {
			type: Number,
			default: undefined
		},
		restricted: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		label(){
			if(!this.interaction.label_short || this.interaction.label_short === ""){
				return this.interaction.number
			}
			return [this.interaction.number, this.interaction.label_short].join( " - ")
		},
		scoreStyle(){
			return {
				backgroundColor: this.score !== undefined ? colorScale( +this.score ) : '',
				color: ( this.score !== undefined && this.score !== 0 ) ? '#FEFEFE' : ''
			}
		}
	},
	methods: {
		handleClick(){
            if( !this.restricted ){
                this.$emit( "click" )
            }
		}
	}
}
</script>

<style lang='scss' scoped>
.interaction-card{
	width: 100%;
	display: flex;
	color: #263238;
	padding: 10px 0;
	border-top: solid 1px #E5E5E5;

  &.restricted{
    .score{
      opacity: 0.3;
    }
    .empty{
      opacity: 0.3;
    }
  }

	.info{
		width: 70%;
		margin-right: 5px;

		.header{
			width: 100%;
			display: flex;
			flex-wrap: nowrap;
			margin-bottom: 10px;

			img{
				width: 60px;
				height: 60px;
				border-radius: 5px;
				border: solid 1px #E5E5E5;
			}

			h4{
				width: calc( 100% - 70px);
				color: #263238;
				margin: 0 0 5px 10px;
				font-size: 14px;
			}
		}

		p{
			font-size: 13px;
		}
	}

	.scoring{
		width: 30%;
		width: 90px;
		height: 90px;
		border-radius: 50%;
		text-align: center;
		margin: 0 auto;
		cursor: pointer;

		.score{
			font-size: 50px;
			font-weight: 600;
			line-height: 90px;
		}
		
		&.empty{
			background-color: #F2F2F2;
			color: #263238;
			border: solid 1px #263238;
			font-family: "Open Sans";
		}
	}
}
</style>