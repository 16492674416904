import { sortBy } from './../../components/Matrix/SummaryStatistics';

function sortItems( sortKey, sortDirection, items){
	return items.slice()
		.sort( sortBy( sortKey, sortDirection ) )
}

export default{
	props: {
		colSort: {
			type: String,
			default: 'sort_order',
		},
		colDirection: {
			type: String,
			default: 'ASC'
		},
		rowSort: {
			type: String,
			default: 'sort_order',
		},
		rowDirection: {
			type: String,
			default: 'ASC',
		},
	},		
	computed: {
		sortedCols(){
			return sortItems( this.colSort, this.colDirection, this.cols );
		},
		sortedRows(){
			return sortItems( this.rowSort, this.rowDirection, this.rows );
		}
	},
}