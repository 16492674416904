<template>
    <div class='matrix' :style="{width: width + 'px', height: height + 'px'}">
        <svg class='canvas' v-if='interactions.length > 0'
            :width='width'
            :height='height'
        >
            <g class='canvas'
                :transform="'translate(' + margin.left + ',' + margin.top + ')'"
            >
                <g class='grid-lines'>
                    <g class='row-grid'>
                        <line v-for='interaction in interactions'
                            :key='interaction.id'
                            class='row-grid-line'
                            x1="0"
                            :x2='canvasWidth'
                            :y1='rowScale(interaction.goal_id)'
                            :y2='rowScale(interaction.goal_id)'
                        >
                        </line>
                    </g>
                    <g class='col-grid'>
                        <line v-for='interaction in interactions'
                            :key='interaction.id'
                            class='col-grid-line'
                            :x1="colScale(interaction.goal_id)"
                            :x2='colScale(interaction.goal_id)'
                            :y1='0'
                            :y2='canvasHeight'
                        >
                        </line>
                    </g>
                    <g class='labels col top'>
                        <text v-for='interaction in interactions'
                            @mouseover='handleGoalHover(interaction)'
                            :key='interaction.id'
                            class='row-top-label hover-info'
                            :x="colScale(interaction.goal_id)"
                            :y="-5"
                            :transform="'rotate(-40 ' + colScale(interaction.goal_id) + ', ' + -5 + ')'"
                        >{{interaction.number}}
                        </text>
                    </g>

                    <g class='labels col bottom'>
                        <text v-for='interaction in interactions'
                            @mouseover='handleGoalHover(interaction)'
                            :key='interaction.id'
                            class='row-top-label hover-info'
                            :x="colScale(interaction.goal_id) - 5"
                            :y="canvasHeight + 17"
                            :transform="'rotate(40 ' + colScale(interaction.goal_id) + ', ' + (canvasHeight + 15) + ')'"
                        >{{interaction.number}}
                        </text>
                    </g>

                    <g class='labels row left'>
                         <text v-for='interaction in interactions'
                            @mouseover='handleGoalHover(interaction)'
                            :key='interaction.id'
                            class='row-top-label hover-info'
                            :y="rowScale(interaction.goal_id)"
                            :x="-5"
                            dominant-baseline='middle'
                        >{{interaction.number}}
                        </text>
                    </g>

                    <g class='labels row right'>
                         <text v-for='interaction in interactions'
                            @mouseover='handleGoalHover(interaction)'
                            :key='interaction.id'
                            class='row-top-label hover-info'
                            :y="rowScale(interaction.goal_id)"
                            :x="canvasWidth + 5"
                            dominant-baseline='middle'
                        >{{interaction.number}}
                        </text>
                    </g>
                </g>

                <g class='null-answers'>
                    <line class='cross topdown'
                        v-for="interaction in interactions"
                        :key="'top-' + interaction.id"
                        :x1="colScale(interaction.goal_id)-4"
                        :x2="colScale(interaction.goal_id)+4"
                        :y1="rowScale(interaction.goal_id)-4"
                        :y2="rowScale(interaction.goal_id)+4"
                        >
                    </line>
                    <line class='cross bottomup'
                        v-for="interaction in interactions"
                        :key="'bottom-' + interaction.id"
                        :x1="colScale(interaction.goal_id)-4"
                        :x2="colScale(interaction.goal_id)+4"
                        :y1="rowScale(interaction.goal_id)+4"
                        :y2="rowScale(interaction.goal_id)-4"
                        >
                    </line>
                </g>

                <g class='score-groups'>
                    <g class='row'
                        v-for='(goal) in interactions'
                        :key='goal.id'
                        :transform="'translate(0, ' + rowScale(goal.goal_id) + ')'"
                    >
                        <g class='answer'
                            v-for='interaction in interactions'
                            :key='interaction.id'    
                            :transform="'translate(' + colScale(interaction.goal_id) + ')'"
                            @click="handleInteractionClick(goal, interaction)"
                        >
                            <circle v-if="goal.goal_id !== interaction.goal_id && hasAnswer(goal, interaction)"
                                class='answer-circle'
                                cx="0"
                                cy="0"
                                :r='radiusScale(getAnswer(goal, interaction).score)'
                                :fill='colorScale(getAnswer(goal, interaction).score)'
                            ></circle>
                            
                            <g :class="['empty-answer', {restricted: !checkRestrictions( goal, interaction ) }]"
                                v-else-if="goal.goal_id !== interaction.goal_id"
                            >
                                <circle
                                    class='answer-circle'
                                    cx="0"
                                    cy="0"
                                    :r='scoreRadius-4'
                                ></circle>

                                <text class='question-mark'
                                    x="0"
                                    y="2"
                                    dominant-baseline="middle"
                                    :font-size='scoreRadius + "px"'
                                >
                                    ?
                                </text>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
        
        <div class='answer-modal' v-if='userType==="respondent" && is_answering' @click='closeModal'>
            <slot name='modal' 
                :answering='answering'
                :event='event'
                :handleGoalHover='handleGoalHover'
                :closeModal='closeModal'
            >
            </slot>
        </div>
    </div>
</template>

<script>
import {scalePoint, scaleQuantize, scaleLinear} from 'd3-scale'
import { scaleColors } from './../../lib/common';

export default {
    components: {
        "answering-modal" : require('./AnsweringModal.vue').default
    },
    data: function(){
        return {
            m_rows : [].concat(this.interactions),
            m_cols : [].concat(this.interactions),
            is_answering: false,
            answering: null
        }
    },
    props: {
        margin: {
            type: Object,
            default: () => {
                return {
                    left: 25,
                    top: 25,
                    right: 25,
                    bottom: 25
                }
            }
        },
        width: {
            type: Number,
            default: 900
        },
        interactions: {
            type: Array,
            default: () => {
                return []
            }
        },
        answers : {
            type: Array,
            default: () => {
                return []
            }
        },
        restrictions: {
            type: Array,
            default: () => {
                return []
            }
        },
        event: {
            type: Number,
            default: 1
        },
        user: {
            type: Object,
            default: () => {
                return null
            }
        },
        userType: {
            type: String,
            default: "respondent"
        },
        answerOptions: {
            type: Array,
            default: () => {
                return [-3,-2,-1,0,1,2,3]
            }
        }
    },
    computed: {
        height(){
            return this.width
        },
        canvasWidth(){
            return this.width - this.margin.left - this.margin.right
        },
        canvasHeight(){
            return this.height - this.margin.top - this.margin.bottom
        },
        rowScale(){
            return scalePoint()
                .domain(this.m_rows.map(i => {return i.goal_id}))
                .range([0, this.canvasHeight])
                .padding(0.5)
                .round(true)
        },
        colScale(){
            return scalePoint()
                .domain(this.m_cols.map(i => {return i.goal_id}))
                .range([0, this.canvasWidth])
                .padding(0.5)
                .round(true)
        },
        colorScale(){
            return scaleQuantize()
                .domain([-3.49999, 3.49999])
                .range( scaleColors )
        },
        answerScale(){
            return scalePoint()
                .domain(this.answerOptions)
                .range([0,400])
                .padding(0.5)
                .round(true)
        },
        scoreRadius(){
            let fromScales = Math.min(this.colScale.step()/2 - 1, this.rowScale.step()/2 - 1)
            let minRadius = 5
            let maxRadius = 30

            return Math.min(maxRadius, Math.max(fromScales, minRadius))
        },
        radiusScale(){
            return () => { return this.scoreRadius };
            // return scaleLinear()
            //     .domain([-3,0,3])
            //     .range([this.scoreRadius, 0.3*this.scoreRadius, this.scoreRadius])

        }
    },
    methods: {
        sortRowsAlpabetically(){
            this.m_rows.sort((a, b) => {
                if(a.number == b.number){
                    return 0
                }
                return a.number < b.number ? -1 : 1
            })
        },
        sortColsAlpabetically(){
            this.m_cols.sort((a, b) => {
                if(a.number == b.number){
                    return 0
                }
                return a.number < b.number ? -1 : 1
            })
        },
        hasAnswer(goal, interaction){
            return this.answers.findIndex(a => {
                return a.goal_id == goal.goal_id && a.interaction_id == interaction.goal_id
            }) > -1
        },
        getAnswer(goal, interaction){
            let idx = this.answers.findIndex(a => {
                return a.goal_id == goal.goal_id && a.interaction_id == interaction.goal_id
            })
            return this.answers[idx]
        },
        addAnswer(){
            let goal = Math.floor(Math.random() * 30)
            let interaction = Math.floor(Math.random() * 30)

            this.answers.push(
                {
                    goal_id : goal,
                    interaction_id : interaction,
                    score: 3
                }
            )
        },
        checkRestrictions( goal, interaction ){
            if( this.restrictions.length < 1 ){
                return true
            }

            const idx = this.restrictions.findIndex( r => {
                return r.goal_id == goal.goal_id && r.interaction_id == interaction.goal_id
            })

            return idx > -1
        },
        handleInteractionClick(goal, interaction){
            if( ! this.checkRestrictions( goal, interaction ) ){
                return
            }

            this.is_answering = true
            
            if(this.hasAnswer(goal, interaction)){
                let answer = this.getAnswer(goal, interaction)
                
                this.answering = 
                    {
                        "id" : answer.id || null,
                        "goal" : goal,
                        "interaction" : interaction,
                        "score" : answer.score,
                        "comment" : answer.comment || null,
                        "uncertain" : answer.uncertain || null
                    }
            } else{
                this.answering = {
                    "goal" : goal,
                    "interaction" : interaction,
                    "score" : null,
                    "comment" : null,
                    "uncertain" : 0
                }
            }
        },
        closeModal(){
            this.is_answering = false
            this.answering = null
        },
        handleGoalHover( goal ){
            this.$emit("goal-hover", goal)
        }
    }
}
</script>

<style>
.empty-answer.restricted{
  opacity: 0.3;
}
</style>