<template>
    <text
        :x="x"
        :y="y"
        :id="id"
        ref='textwrapper'
        :fill='color'
        :text-anchor='textAnchor'
        dominant-baseline="middle"
        :class="'textbox ' + customClass"
        :font-family='fontFamily'
        :font-size="fontSize + 'px'"
    >
    </text>
</template>

<script>
import {select} from 'd3-selection'

export default {
    props: {
        width: {
            type: Number,
            default: 200
        },
        x : {
            type: Number,
            default: 0
        },
        y : {
            type: Number,
            default: 0
        },
        name: {
            type: String,
            default: "textbox"
        },
        lineHeight :{
            type: Number,
            default: 1.1
        },
        heightMeasure: {
            type: String,
            default :"em"
        },
        content: {
            type: String,
            required: true
        },
        textAnchor: {
            type: String,
            default: "start"
        },
        customClass: {
            type: String,
            default: ""
        },
        verticalAlign: {
            type: String,
            default: "middle"
        },
        fontSize: {
            type: Number,
            default: 10
        },
        color: {
            type: String,
            default: null
        },
        fontFamily: {
            type: String,
            default: "sans-serif"
        }
    },
    computed :{
        id(){
            return this.name.replace(/\s+/g, "_");
        },
        style(){
            let style = {
                "textAnchor" : this.textAnchor
            }

            // if(this.color !== null){
            //     style["fill"] = this.color
            // }

            return style
        }
    },
    methods :{
        wrapText(){
            let wrapper  = select(this.$refs.textwrapper)
                .text(null)
        
            let words = this.content.split(/\s+/).reverse(),
                word,
                line = [],
                lineNumber = 0,
                wordNumber = 0,
                tspan = wrapper.append("tspan")
                    .attr("x", this.x)
                    .attr("y", this.y)
                    .attr("dy", 0)
    
            while(word = words.pop()){
                wordNumber += 1
                line.push(word)
                tspan.text(line.join(" "))

                let textWidth = tspan.node().getComputedTextLength()
                                
                if(textWidth === 0){
                    let fontSize = parseInt(tspan.node().style.fontSize.replace("px", "")) || this.fontSize
                    textWidth = line.join(" ").length * 0.45*fontSize
                }
            
                if(textWidth > this.width && line.length > 1){
                    line.pop()
                    tspan.text(line.join(" "))
                    
                    line = [word]
                    lineNumber += 1
                    tspan = wrapper.append("tspan")
                        .attr("x", this.x)
                        .attr("y", this.y)
                        .attr("dy", ((lineNumber) * this.lineHeight) + this.heightMeasure)
                        .text(word)
                    }      
                }
            
                if(lineNumber > 0 && this.verticalAlign === "middle"){
                    let node = wrapper.node()
                    let h = node.getBBox().height
                    let transform = -0.25*h
                    wrapper.attr("transform", "translate( 0, " + transform + ")")
                }
            },
        cleanText(){
            let wrapper  = select(this.$refs.textwrapper)
            wrapper.selectAll('tspan').remove()
        }
    },
    mounted : function(){
        this.wrapText()
    },
    updated: function(){
        this.cleanText()
        this.wrapText()
    },
    watch: {
        content : function(){
            this.cleanText()
            this.wrapText()
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
