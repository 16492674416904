<template>
    <div class='pager-container' ref='pager_container'
        :style="{height: height + units, 
            width: width + units
        }"
    >
        <slot 
            :height='height'
            :width='width'
            :changePage='changePage' 
            :pagePosition='getPagePosition'
            :startPage='startPage'
        >
        </slot>
        <div class='page-indicators' v-if='showIndicators'>
            <div class='indicators'>
                <div v-for='(p, i) in labels'
                    :key='i'
                >
                    <div :class='["indicator", {selected : i===page}]'>

                    </div>
                </div>
            </div>
            <span v-if='showLabels' class='label'>{{labels[page]}}</span>
        </div>
    </div>
</template>

<script>
export default {
    data: function(){
        return {
            page: this.startPage,
        }
    },
    props: {
        width: {
            type: Number,
            default: 100
        },
        height: {
            type: Number,
            default: 100
        },
        units: {
            type: String,
            default: "%"
        },
        startPage: {
            type: Number,
            default: 0
        },
        labels: {
            type: Array,
            default: () => {
                return []
            }
        },
        showIndicators: {
            type: Boolean,
            default: true
        },
        showLabels: {
            type: Boolean,
            default: true
        }
    },
    computed: {
    },
    methods: {
        changePage( value ){
            this.page = value
        },
        getPagePosition( order ){
            return (order - this.page) * this.width + this.units
        }
    },
}
</script>

<style lang='scss' scoped>
.pager-container{
    overflow: hidden;
    position: relative;
}
</style>