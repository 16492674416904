<template>
    <div class='page-container'
        :style='{
            top: "0px", 
            left: position,
            width: "inherit",
            height: "inherit"
        }'
    >
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        position: {
            type: [Number, String],
            default: 0
        },
    }
}
</script>

<style lang='scss' scoped>
.page-container{
    position: absolute;
    transition: left 0.5s;

    .page-label{
        position: absolute;
        bottom: 5px;
        text-align: center;
        width: 100%;
    }
}
</style>