<template>
    <div class='answer-options'
        :style='{width: width + "px"}'
    >
        <svg class='answer-options'
            :width="width"
            :height="height"
        >
            <g class='options'
                :transform="'translate(0, ' + height/2 +')'"
            >
                <g v-for='option in options'
                    :key='option'
                    class='option'
                    @click='handleClick(option)'
                    :opacity='isChosen(option) ? 1 : 0.2'
                >
                    <circle
                        cy="0"
                        :cx="answerScale(option)"
                        :r="height/2-2"
                        :fill="colorScale(option)"
                    ></circle>

                    <text class='label'
                        :x="answerScale(option)"
                        y="2"
                        dominant-baseline="middle"
                        :font-size="height/2"
                    >{{option}}</text>
                </g>
            </g>
        </svg>
    </div>
</template>

<script>
import {scalePoint, scaleOrdinal} from 'd3-scale'

export default {
    data: function(){
        return {
            m_score : this.score
        }
    },
    props: {
        width: {
            type: Number,
            default: 400
        },
        height: {
            type: Number,
            default: 50
        },
        options: {
            type: Array,
            default: () => {
                return [3, -2, -1, 0, 1, 2, 3]
            }
        },
        score: {
            type: Number,
            default: null
        },
        colorScale: {
            type: Function,
            default: scaleOrdinal()
                .domain([-3, -2, -1, 0, 1, 2, 3])
                .range(["red", "yellow", "blue", "green"])
        }
    },
    computed: {
        answerScale(){
            return scalePoint()
                .domain(this.options)
                .range([0, this.width])
                .padding(0.5)
                .round(true)
        }
    },
    methods: {
        handleClick( option ){
            this.$emit("answer-click", option)
        },
        isChosen( option ){
            if(this.score === null){
                return true
            }
            return this.score === option
        }
    }
}
</script>

<style>

</style>