import { rowSum, colSum, square, transpose, vectorMultiply } from "./matrix"

function secondOrderOutEffect( adjacencyMatrix, row){
    return adjacencyMatrix[row].reduce((sum, value, i) => {
        return sum + value * rowSum(adjacencyMatrix, i)
    }, 0)
}

function secondOrderInEffect( adjacencyMatrix, col ){
    return transpose(adjacencyMatrix)[col].reduce((sum, value, i) => {
        return sum + value * colSum(adjacencyMatrix, i)
    })
}

/**
 * 
 * @param {Array} nodes 
 * @param {Array} edges 
 * @param {String} id_var 
 * @param {String} score_var
 */
export const adjacencyMatrix = ( nodes, edges, id_var, score_var, sourceVar, targetVar) => {
    let matrix = []
    let source = sourceVar || "source"
    let target = targetVar || "target"
    
    nodes.forEach( (node, i) => {
        let row = []
        
        nodes.forEach( (interaction, i) => {
            let edgeIndex = edges.findIndex(e => {
                return e[source] === node[id_var] && e[target] === interaction[id_var]
            })
           
            if(edgeIndex > -1){
                let edge = edges[edgeIndex]

                if(score_var !== undefined){
                    row.push(edge[score_var])
                } else{
                    row.push(edge)
                }
                
            } else {
                row.push(null)
            }
        })

        matrix.push( row )
    })

    return matrix
}

export const outDegree = (adjacencyMatrix, rowIndex) => {
    return rowSum(adjacencyMatrix, rowIndex)
}

export const inDegree = (adjacencyMatrix, colIndex) => {
    return colSum(adjacencyMatrix, colIndex)
}

export const secondOrderOut = ( adjacencyMatrix,  rowIndex, weight) => {
    return rowSum(adjacencyMatrix, rowIndex) + weight * secondOrderOutEffect(adjacencyMatrix, rowIndex)
}

export const secondOrderIn = ( adjacencyMatrix, colIndex, weight) => {
    return colSum(adjacencyMatrix, colIndex) + weight * secondOrderInEffect(adjacencyMatrix, colIndex)
}

export const secondOrderRadialOut = (adjacencyMatrix, rowIndex, colIndex) => {
    return vectorMultiply(adjacencyMatrix[rowIndex], transpose(adjacencyMatrix)[colIndex] )
}

export const secondOrderRadialIn = (adjacencyMatrix, rowIndex, colIndex) => {
    return vectorMultiply(adjacencyMatrix[rowIndex], transpose(adjacencyMatrix)[colIndex])
}