<script>
export default {
    name: "LevelOverlay.vue",
    props: {
        levels: {
            type: Array,
            required: true
        },
        padding : {
            type: Object,
            default: {
                top: 50,
                left: 50,
                bottom: 30,
                right: 30
            }
        },
        row: {
            type: Object,
            required: true
        },
        col: {
            type: Object,
            required: true
        },
        rowIdx: {
            type: Number,
            required: true
        },
        colIdx: {
            type: Number,
            required: true
        },
        height: {
            type: Number,
            required: true
        },
        visible: {
            type: Object || null,
            default: null
        }
    },
    methods: {
        getPadding(){
            let padding = [0,0,0,0];

            if( this.rowIdx === 0){
                padding[0] = this.padding.top + "px";
            }
            if( this.colIdx === 0){
                padding[3] = this.padding.left + "px";
            }
            if( this.rowIdx === this.levels.length - 1){
                padding[2] = this.padding.bottom + "px";
            }
            if( this.colIdx === this.levels.length - 1){
                padding[1] = this.padding.right + "px";
            }

            return padding.join( " " );
        },
        handleClick(){
            this.$emit( "level-click", {row: this.row, col: this.col} )
        },
        levelIsVisible(){
            if( this.visible === null ){
                return false
            }
            return this.visible.row.id === this.row.id && this.visible.col.id === this.col.id
        }
    }
}
</script>

<template>
    <div class="level-info" :class="{visible: this.levelIsVisible() }" :style='"height:" + height + "px; padding: " + this.getPadding()'
         @click="this.handleClick"
    >
        <div class="level-info-text">
            <p>{{row.name}} <i class="fas fa-arrow-right"></i> {{col.name}}</p>
        </div>
    </div>
</template>

<style scoped>
.level-info{
    width: 100%;
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.4s;
    cursor: pointer;
}
.level-info.visible{
    opacity: 0;
    z-index: -20;
}

.level-info-text{
    width: calc( 100% - 40px );
    height: calc( 100% - 40px );
    background-color: #EAEFF1D0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.level-info-text p{
    text-align: center;
    color: #17344E;
    font-weight: 400;
}
</style>