<script>
import MatrixSvg from "./MatrixSvg.vue";
import {colorScale} from "../../lib/common";
import GoalInfo from "./GoalInfo.vue";
import ScoreLegend from "../ScoreLegend.vue";
import AnsweringModal from "./AnsweringModal.vue";
import LevelOverlay from "./LevelOverlay.vue";
import FloatingSettings from "../FloatingSettings.vue";
import MobileLevelMatrix from "./Mobile/MobileLevelMatrix.vue";
export default {
    name: "RespondentLevelMatrix",
    methods: {
        colorScale,
        closeModal(){
            this.answering = null
        },
        handleInteractionClick( interaction ){
            if( this.restrictions.length ){
                let idx = this.restrictions.findIndex( r => {
                    return r.goal_id === interaction.goal.goal_id &&
                        r.interaction_id === interaction.relation.goal_id &&
                        r.goal_administrative_level === interaction.goal.administrative_level &&
                        r.interaction_administrative_level === interaction.relation.administrative_level
                } )
                if( idx < 0 ){
                    return;
                }
            }
            this.answering = interaction;
            this.answering.interaction = this.answering.relation;
        },
        handleAnswerAdd( answer ){
            const idx = this.answers.findIndex( a => a.id === answer.id );
            if( idx > -1){
                this.answers.splice( idx, 1, answer );
            } else {
                this.answers.push( answer );
            }
        },
        handleAnswerDelete( oldAnswerId ){
            let idx = this.answers.findIndex( a => a.id === oldAnswerId )
            if( idx > -1 ){
                this.answers.splice( idx, 1 );
            }
        },
        handleGoalHover( goal ){
            this.hovered = goal
        },
        handleLevelClick( level ){
            this.levelVisible = level
        }
    },
    components: {
        MobileLevelMatrix,
        FloatingSettings,
        AnsweringModal,
        ScoreLegend,
        GoalInfo,
        MatrixSvg,
        LevelOverlay
    },
    props: {
        event: {
            type: Number,
            required: true
        },
        answers: {
            type: Array,
            default: []
        },
        levels: {
            type: Array,
            default: () => []
        },
        goals: {
            type: Array,
            default: () => []
        },
        restrictions: {
            type: Array,
            default: () => []
        }
    },
    data: function(){
        return {
            error: undefined,
            columns: [],
            rows: [],
            levelsWithGoals: [],
            loading: true,
            hovered: null,
            answering: null,
            levelVisible: null,
        }
    },
    mounted(){
        this.levelsWithGoals = this.levels.map( level => {
            level.goals = this.goals.filter( goal => goal.administrative_level === level.administrative_level_id );
            return level;
        } )
        this.columns = this.levelsWithGoals.reduce( (prev, cur) => prev.concat( cur.goals ), [] );
        this.rows = this.columns;
        this.loading = false;
    }

}
</script>

<template>
    <MobileLevelMatrix :event="event" :answers="answers" :levels="levelsWithGoals" :restrictions="restrictions" />
    <div class="level-matrix-container">
        <div class="level-matrix-info">
            <GoalInfo v-if='hovered !== null' :goal='hovered' />
            <div class='legend'>
                <svg width="250" height="300" id='legend'>
                    <ScoreLegend
                        :width="250"
                        :values="[
                                            {value: 3, label : '3'},
                                            {value: 2, label : '2'},
                                            {value: 1, label : '1'},
                                            {value: 0, label : '0'},
                                            {value: -1, label : '-1'},
                                            {value: -2, label : '-2'},
                                            {value: -3, label : '-3'}
                                        ]"
                        :colorScale="colorScale"
                        :fixed-circle-size='true'
                    />
                </svg>
            </div>
        </div>
        <div class="level-matrix" v-if="!loading">
            <div class="level-row" v-for="(levelRow, i) in levelsWithGoals">
                <div class="level-column" v-for="(levelCol, j) in levelsWithGoals">
                    <MatrixSvg :color-scale="colorScale"
                               :rows="levelRow.goals"
                               :cols="levelCol.goals"
                               :answers="answers"
                               :width="900*(levelCol.goals.length/columns.length)"
                               :height="900*(levelRow.goals.length/rows.length)"
                               :margin="{
                                   top: i === 0 ? 50 : 0,
                                   left: j === 0 ? 50 : 0,
                                   bottom: i === levels.length - 1 ? 30 : 0 ,
                                   right: j === levels.length - 1 ? 30 : 0
                               }"
                               :show-top-column-labels="i === 0"
                               :show-bottom-column-labels="i === levels.length - 1"
                               :show-left-row-labels="j === 0"
                               :show-right-row-labels="j === levels.length - 1"
                               :restrictions="restrictions"
                               @goal-hover="handleGoalHover"
                               @interaction-click="handleInteractionClick"
                    />
                    <LevelOverlay
                        :height="900*(levelRow.goals.length/rows.length)"
                        :col-idx='j' :row-idx='i'
                        :col="levelCol" :row="levelRow"
                        :levels="this.levels"
                        :visible="this.levelVisible"
                        @level-click="this.handleLevelClick"
                    />

                </div>
            </div>
            <div class="answer-modal" v-if="answering !== null">
                <answering-modal
                    :answering='answering'
                    :event='event'
                    @added='( a ) => {
                            handleAnswerAdd( a )
                            closeModal()
                        }'
                    @removed='( a ) => {
                            handleAnswerDelete( a )
                            closeModal()
                        }'
                    @goal-hover='handleGoalHover'
                    @close='closeModal'
                ></answering-modal>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .level-matrix-container{
        width: 1200px;
        display: none;
        margin: 60px auto;
    }
    .level-matrix-info{
        width: 300px;
        height: 900px;
        position: relative;
    }
    .level-matrix{
        position: relative;
    }
    .level-row{
        display: flex;
    }
    .level-column{
        position: relative;
    }

    .legend{
        position: absolute;
        bottom: 0;
    }

    @media screen and (min-width: 1024px) {
        .level-matrix-container{
            display: flex;
        }
        .level-matrix-container-mobile{
            display: none;
        }
    }
</style>