<template>
   <div class='matrix-modal respondent' @click.stop>        
        <i class='fas fa-times close-button' @click='handleClose'></i>
        <div class='header'>
            <span class='goal hover-info' @mouseover='handleGoalHover(answering.goal)'>
                {{answering.goal.number}}
            </span>
            <img class='connector' src='/images/arrow-connect.png' />
            <span class='interaction hover-info' @mouseover='handleGoalHover(answering.interaction)'>
                {{answering.interaction.number}}
            </span>
        </div>
        
        <div class='content'>
            <p class='question' v-if="answering.goal.administrative_level !== 0">
                {{$t("respondent.question.if advance")}}
                <strong class='hover-info' @mouseover='handleGoalHover(answering.goal)'>
                    {{$t( "respondent." + answering.goal.type )}} {{answering.goal.number}}</strong> {{$t("respondent.in")}} {{answering.goal.administrative_level_name}}
                {{$t("respondent.question.influence progress")}}
                <strong class='hover-info'
                        @mouseover='handleGoalHover(answering.interaction)'
                >{{$t( "respondent." + answering.interaction.type ) }} {{answering.interaction.number}}</strong> {{$t("respondent.in")}} {{answering.interaction.administrative_level_name}} ?
            </p>
            <p class='question' v-else>
                {{$t("respondent.question.if advance")}} 
                    <strong class='hover-info' @mouseover='handleGoalHover(answering.goal)'>
                        {{answering.goal.number}}</strong>
                {{$t("respondent.question.influence progress")}}
                    <strong class='hover-info'
                        @mouseover='handleGoalHover(answering.interaction)'
                    >{{answering.interaction.number}}</strong>?
            </p>
            <answer-options
                :options='answerOptions'
                :colorScale='colorScale'
                @answer-click='handleAnswerClick'
                :score='m_answering.score'
                :width='mobile ? 275 : 400'
                :height='mobile ? 40 : 50'
            >
            </answer-options>
            <div class='comment input'>
                <textarea 
                    :placeholder='$t("respondent.comment")'
                    v-model='m_answering.comment'    
                >
                </textarea>
            </div>
            <p class='sure-text'>
                {{$t("respondent.not sure")}}
            </p>
            <toggle-switch
                :labels="{
                        on: $t('respondent.toggle.not sure'),
                        off: $t('respondent.toggle.sure')
                    }"
                :value="m_answering.uncertain"
                @toggled="handleUncertainToggle"
            >
            </toggle-switch>
                       
            <div v-if='answering.score !== null'
                class='paging-buttons'>
                
                <div v-if='answering.id !== undefined'
                    class='page-button previous'
                    @click='() => {confirming = true}'
                >
                    {{$t('buttons.Remove Answer')}}
                </div>
                <ajax-button
                    class='page-button next'
                    :request="{
                        method: 'POST',
                        url: '/respondent/answer/set',
                        data: {
                            event_id : event,
                            goal_id: answering.goal.goal_id,
                            interaction_id: answering.interaction.goal_id,
                            score: m_answering.score,
                            comment: m_answering.comment,
                            uncertain: m_answering.uncertain || false,
                            goal_administrative_level: answering.goal.administrative_level,
                            interaction_administrative_level: answering.interaction.administrative_level
                        }
                    }"
                    @success="handleAnswerAdd"
                >
                    {{$t('buttons.Send Answer')}}
                </ajax-button>
                
            </div>
        </div>

        <div class='sure-modal' v-if='confirming'>
            <div class='header'>
                <span class='goal hover-info' @mouseover='handleGoalHover(answering.goal)'>
                    {{answering.goal.number}}
                </span>
                <img class='connector' src='/images/arrow-connect.png' />
                <span class='interaction hover-info' @mouseover='handleGoalHover(answering.interaction)'>
                    {{answering.interaction.number}}
                </span>
            </div>
            <div class='content'>
                <answer-options
                    :options='answerOptions'
                    :colorScale='colorScale'
                    :score='m_answering.score'
                    :width='mobile ? 275 : 400'
                    :height='mobile ? 40 : 50'
                ></answer-options>
                <p class='question sure'>
                    {{$t("respondent.sure to remove")}} 
                        <strong class='hover-info' @mouseover='handleGoalHover(answering.goal)'>{{answering.goal.number}}</strong> 
                            {{$t('general.and')}} 
                        <strong class='hover-info' @mouseover='handleGoalHover(answering.interaction)'>{{answering.interaction.number}}</strong>?
                </p>
            </div>
            <div class='paging-buttons'>
                <div v-if='answering.id !== undefined'
                    class='page-button next'
                    @click='() => {confirming = false}'
                >
                    {{$t('buttons.Cancel')}}
                </div>
                <ajax-button v-if='answering.id !== undefined'
                        class='page-button previous'
                        :request="{
                            method: 'POST',
                            url: '/respondent/answer/delete',
                            data: {
                                event_id: event,
                                answer_id: answering.id
                            }
                        }"
                        @success='handleAnswerDelete(answering.id)'
                    >
                        {{$t('buttons.Remove Answer')}}
                </ajax-button>
            </div>
        </div>
    </div>
</template>

<script>
import { colorScale } from '../../lib/common';

export default {
    components : {
        "pager" : require("./../Pager/Pager.vue").default,
        "page" : require("./../Pager/Page.vue").default,
        "toggle-switch" : require("./../forms/buttons/Switch.vue").default,
        "answer-options" : require('./AnswerOptions.vue').default,
        "ajax-button" : require('./../forms/buttons/AjaxButton.vue').default
    },
    data: function(){
        return {
            m_answering: this.answering,
            confirming: false
        }
    },
    props: {
        event: {
            type: Number,
            required: true
        },
        answering : {
            type: Object,
            default: null
        },
        answerOptions : {
            type: Array,
            default: () => {
                return [-3, -2, -1, 0, 1, 2, 3]
            } 
        },
        mobile: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        handleAnswerClick( value ){
            this.m_answering.score = value            
        },
        handleUncertainToggle( value ){
            this.m_answering.uncertain = value
        },
        handleAnswerAdd( value ){
            if(Array.isArray(value)){
                value = value[0]
            }
            this.$emit("added", value)
        },
        handleAnswerDelete( answer_id ){
            this.$emit("removed", answer_id)
        },
        handleAnswerFailure( value ){
            console.log(value)
        },
        handleGoalHover( goal ){
            this.$emit('goal-hover', goal)
        },
        handleClose(){
            this.$emit('close')
        },
        colorScale
    },
    mounted() {
        console.log( this.answering.goal)
    }

}
</script>

<style>

</style>