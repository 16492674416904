export const transpose = ( matrix ) => {
    return matrix[0].map((_, i) => {
        return matrix.map((row) => {
            return row[i]
        })
    })
}

export const multiply = ( A, B ) => {
    let result = new Array(A[0].length).fill(0).map(row => {
        return new Array(B[0].length).fill(0)
    })

    return result.map((row, i) => {
        return row.map((col, j) => {
            return A[i].reduce((sum, elm, k) => {
                return sum + (elm * B[k][j])
            }, 0)
        })
    })
}

export const square = ( matrix ) =>{
    return multiply(matrix, transpose(matrix))
}

export const vectorMultiply = ( A, B ) => {
    return A.reduce((sum, val, i) => {
        return sum + val*B[i]
    }, 0)
}

export const rowSum = (matrix, rowIndex) => {
    return matrix[rowIndex].reduce((sum, val) => sum+val, 0)
}

export const colSum = (matrix, colIndex) => {
    return rowSum(transpose(matrix), colIndex)
}

export const sortByDegree = function sortByDegree(a, b, order, direction ){
    let o = order || "DESC"
    let dir = direction || "out"

    let aVal = dir == "out" ? a.outDegree : a.inDegree
    let bVal = dir == "out" ? b.outDegree : b.inDegree

    if(aVal == bVal){
        return 0
    }
    if(o == "DESC"){
        return aVal > bVal ? -1 : 1
    } else {
        return aVal > bVal ? 1 : -1
    }
}