<template>
	<div class='floating bottom left floating-settings'>
		 <div class='state-icon' @click.stop='toggleState'>
            <i v-if="!opened" class='fas fa-cog'></i>
            <i v-if="opened" class='fas fa-times'></i>
        </div>

		<div :class="['settings-container', { opened } ]">
			<LanguageSwitcher :languages="[ 'en', 'es-CO' ]" base-url='/set-locale/' />
		</div>
	</div>
</template>

<script>
import LanguageSwitcher from './LanguageSwitcher.vue';

export default {
	components: {
		LanguageSwitcher,
	},
	data: function(){
		return {
			opened: false,
		}
	},
	methods: {
		toggleState: function(){
			this.opened = !this.opened
		},
	}
}
</script>

<style lang='scss'>
.floating-settings{
	.state-icon{
		float: left;
	}

	.settings-container{
		width: 0;
		transition: width 0.4s 0.2s;

		.language-switcher{
			float: right;
			opacity: 0;
			transition: opacity 0.2s;
			padding: 0 5px;
			height: 30px;
		}

		&.opened{
			width: calc( 100vw - 40px );
			transition: width 0.4s;

			.language-switcher{
				opacity: 1;	
				transition: opacity 0.2s 0.4s;
			}
		}

		
	}

	
}

</style>