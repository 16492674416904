require( './base' );

/**
 * Get the required styles
 */
import './sass/respondent.scss';
/**
 * Register components required for the respondent views
 */
Vue.component('respondent-login', require('./components/forms/login/RespondentLogin.vue').default)
Vue.component('respondent-matrix', require('./components/Matrix/RespondentMatrix.vue').default)
Vue.component('respondent-level-matrix', require('./components/Matrix/RespondentLevelMatrix.vue').default)

Vue.mount( "#app" );

