import './sass/base.scss';
import { createApp } from 'vue';
import { createI18n }from 'vue-i18n';
const messages = require('./languages/messages').default;

global.Vue = createApp({} );

// Setup the translations
global.i18n = createI18n( {
    locale: document.getElementsByTagName("html")[0].getAttribute("lang"),
    messages
})

Vue.use( global.i18n );

/**
 * Turn off vue devtools in production
 */
Vue.config.devtools = true

// Require axios
global.axios = require('axios');