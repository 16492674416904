<template>
    <div class='language-switcher'>
        <div class='label'>
            <p>{{$t('general.language')}}</p>
        </div>
        <div class='languages'>
            <a v-for='lang in languages'
                :key='lang'
                :href='baseUrl + lang'
            >
                <img class='lang-flag' :src='flagUrl(lang)' />
            </a>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        languages: {
            type: Array,
            default: () => ["en"]
        },
        imageUrl: {
            type: String,
            default: "/images/flags/"
        },
        baseUrl: {
            type: String,
            required: true
        }
    },
    methods: {
        flagUrl( lang ){
            return this.imageUrl + "/" + lang + ".png"
        },
    }
}
</script>

<style>

</style>