<template>
    <g class='score-legend'
        :transform="'translate(' + x + ',' + y + ')'"
    >
        <g class='margin'
            :transform="'translate(' + margin.left + ',' + margin.top + ')'"
            v-if='values.length > 0'
        >
            <g v-for='(d, i) in values'
                :key='"group-" + i'
                class='legend-group'
                :transform="'translate(0, ' + positionScale(d.value) + ')'"
            >
                <text class='legend-value' v-if='showValues'
                    x="0"
                    y="0"
                    dominant-baseline="middle"
                    :style='{
                        fill: d.value !== 0 ? colorScale(d.value) : "",
                    }'
                    
                >
                    {{d.value}}
                </text>

                <circle
                    :cx="showValues ? ( sizeScale.range()[1] + 20 ) : ( sizeScale.range()[1] + 5 )"
                    cy=0
                    :r="!fixedCircleSize ? sizeScale(Math.abs(d.value)) : maxSize"
                    :fill="colorScale(d.value)"
                ></circle>

                <text-box class='legend-label'
                    :x="showValues ? 4*sizeScale.range()[1] : ( sizeScale.range()[1]*2 + 10 )"
                    :y="0"
                    :width="showValues ? ( canvasWidth-4*sizeScale.range()[1] ) : ( canvasWidth - ( sizeScale.range()[1]*2 + 10 ) )"
                    :content="$t('legend.' + d.label)"
                    :font-size="13"
                    :lineHeight="1"
                >
                    
                </text-box>
            </g>
        </g>
    </g>
</template>

<script>
import {scalePoint, scaleLinear} from 'd3-scale'

const messages = {
    "es-CO" : {
        "-3" : "Limitando fuertemente",
        "-2" : "Limitando moderadamente",
        "-1" : "Limitando débilmente",
        "0" : "Sin influencia",
        "1" : "Promoviendo débilmente",
        "2" : "Promoviendo moderadamente",
        "3" : "Promoviendo fuertemente"
    },
    "en" : {

    }
}

export default {
    components : {
        'text-box' : require('./SvgTextbox.vue').default
    },
    props: {
        height: {
            type: Number,
            default: 300
        },
        width: {
            type: Number,
            default: 160
        },
        x: {
            type: Number,
            default: 0
        },
        y: {
            type: Number,
            default: 0
        },
        margin: {
            type: Object,
            default: function(){
                return {
                    top: 10,
                    right: 10,
                    left: 10,
                    bottom: 10
                }
            }
        },
        values: {
            type: Array,
            default: function(){
                []
            }
        },
        colorScale: {
            type: Function,
            required: true
        },
        showValues: {
            type: Boolean,
            default: true
        },
        fixedCircleSize: {
            type: Boolean,
            default: false
        },
        circleRadius: {
            type: Number,
            default: 15 
        }
    },
    computed: {
        canvasWidth(){
            return this.width - this.margin.left - this.margin.right
        },
        canvasHeight(){
            return this.height - this.margin.top - this.margin.bottom
        },
        maxSize(){
            return Math.min( 25, this.positionScale.step()/2) - 1
        },
        sizeScale(){
            return scaleLinear()
                .domain([ 0, 3 ] )
                .range( [ 8, this.maxSize ] )
        },
        positionScale(){
            return scalePoint()
                .domain(this.values.map(d => {return d.value}).sort((a, b) => {
                    if(a == b) return 0

                    return a < b ? -1 : 1
                }))
                .range([this.canvasHeight, 0])
                .padding(0.5)
        }
    }
}
</script>

<style>

</style>