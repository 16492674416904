<script>
import RowContainer from "./RowContainer.vue";
import InteractionContainer from "./InteractionContainer.vue";
import AnsweringModal from "../AnsweringModal.vue";
import FloatingSettings from "../../FloatingSettings.vue";

export default {
    name: "MobileLevelMatrix.vue",
    components: {FloatingSettings, AnsweringModal, InteractionContainer, RowContainer},
    props: {
        levels: {
            required: true,
        },
        answers: {
            required: true
        },
        event: {
            required: true
        },
        restrictions: {
            default: () => []
        }
    },
    data: function(){
        return {
            answering: undefined
        }
    },
    methods: {
        getAnswer( goal, interaction ){
            let idx = this.answers.findIndex( a => {
                return a.goal_id === goal.goal_id && a.interaction_id === interaction.goal_id
                    && a.goal_administrative_level === goal.administrative_level && a.interaction_administrative_level === interaction.administrative_level
            } )
            if( idx > -1 ){
                return this.answers[ idx ]
            }
            return undefined
        },
        getScore( goal, interaction ){
            let answer = this.getAnswer( goal, interaction )

            if( answer !== undefined ){
                return answer.score
            }

            return undefined
        },
        isRestricted( goal, interaction ){
            if( this.restrictions.length < 1 ){
                return false
            }

            let idx = this.restrictions.findIndex( r => {
                return r.goal_id === goal.goal_id &&
                    r.interaction_id === interaction.goal_id &&
                    r.goal_administrative_level === goal.administrative_level &&
                    r.interaction_administrative_level === interaction.administrative_level
            } )
            return idx < 0
        },
        handleClick( goal, interaction ){
            if( this.isRestricted( goal, interaction) ){
                return;
            }
            const answer = this.getAnswer( goal, interaction )

            if( answer === undefined){
                this.answering = {
                    goal,
                    interaction,
                    score: undefined,
                    comment: undefined,
                    uncertain: 0
                }
            } else {
                answer.goal = goal
                answer.interaction = interaction
                this.answering = answer
            }
        },
        handleAnswerAdd( answer ){
            const idx = this.answers.findIndex( a => a.id === answer.id );
            if( idx > -1){
                this.answers.splice( idx, 1, answer );
            } else {
                this.answers.push( answer );
            }
        },
        handleAnswerDelete( oldAnswerId ){
            let idx = this.answers.findIndex( a => a.id === oldAnswerId )
            if( idx > -1 ){
                this.answers.splice( idx, 1 );
            }
        }
    }
}
</script>

<template>
    <div class="level-matrix-container-mobile">
        <div class="level-row-container" v-for="(row, i) in levels">
            <div class="level-col-container" v-for="(col, j) in levels">
                <p class="level-column-label">{{row.name}} <i class="fas fa-arrow-right"></i> {{col.name}}</p>
                <RowContainer v-for="goal in row.goals"
                              :key="goal.id"
                              :goal="goal"
                >

                    <template #interactions>
                        <InteractionContainer v-for="interaction in col.goals.filter( g => {
                            if( row.id !== col.id ){
                                return true;
                            }
                            return goal.id !== g.id
                        })"
                                              :key="interaction.id"
                                              :interaction="interaction"
                                              :score="getScore( goal, interaction )"
                                              :restricted="isRestricted(goal, interaction)"
                                              @click="handleClick( goal, interaction )"
                                              />
                    </template>
                    <template v-slot:overlay="{showIndicator, interactionsVisible, toggleInteractions}">
                        <transition name="fade">
                            <div class='floating top right' v-if='interactionsVisible && showIndicator && !answering'>
                                <div class='answering-indicator'>
                                    <p class="level-info">{{row.name}}</p>
                                    <p class="level-info">-</p>
                                    <p class="level-info">{{col.name}}</p>
                                    <p class="number">{{ goal.number }}</p>
                                    <img :src='goal.image' class="mobile-indicator"/>
                                    <a @click='toggleInteractions' class="toggle interactions">
                                        <i class='fas fa-caret-square-up'></i>
                                        {{ $t( 'matrix.hide interactions') }}
                                    </a>
                                </div>
                            </div>
                        </transition>
                    </template>
                </RowContainer>
            </div>
        </div>
        <div class='modal-backdrop' v-if='answering !== undefined'>
            <AnsweringModal
                :answering="answering"
                :event='event'
                :mobile='true'
                @close="answering = undefined"
                @added='( a ) => {
                            handleAnswerAdd( a )
                            answering = undefined
                        }'
                @removed='( a ) => {
                            handleAnswerDelete( a )
                            answering = undefined
                        }'
            />
        </div>
        <FloatingSettings v-if="answering === undefined" />
    </div>
</template>

<style scoped lang="scss">
    .goal-card{
        margin-bottom: 10px;
    }
    .level-matrix-container-mobile{

    }
    .level-column-label{
        width: 90%;
        min-width: 300px;
        max-width: 500px;
        margin: 30px auto 0;
        background-color: #17344e;
        color: #FFF;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        padding: 10px 15px;
    }

    .level-col-container .goal-card:first-of-type{
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity 0.4s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
</style>