<template>
    <div class='respondent-matrix-container'>
         <mobile-matrix
            :goals='goals'
            :answers='m_answers'
            :event='event'
            :restrictions="restrictions"
        >
            <template #before>
                <div class="container" id="matrix-explanation">
                    <div class="row">
                        <div class="col-12">
                            <h2>{{ $t('matrix.targets') }}</h2>
                            <p>{{ $t('matrix.mobile.help') }}</p>
                        </div>
                    </div>
                </div>
            </template>

            <template v-slot:modal='{answering, event, closeModal}'>
                <AnsweringModal 
			    	:answering="answering" 
			    	:event='event'
			    	:mobile='true'
			    	@close="closeModal"
			    	@added='( a ) => {
                        handleAnswerAdd( a )
                        closeModal()
                    }'
			    	@removed='( a ) => {
                        handleAnswerDelete( a )
                        closeModal()
                    }'
			    />
            </template>

            <template v-slot:after='{ answering }'>
                <FloatingSettings v-if='answering === undefined' />
            </template>
        </mobile-matrix>

        <div class='matrix-view'>
            <div class='matrix-sidebar'
                style="width: calc(100% - 900px)"
            >
                <!-- <img
                    class='logo'
                    v-if='logo !== null'
                    :src='logo'
                /> -->

                <div class='help' v-if='helpText !== null'>
                    <p>
                        {{helpText}}
                    </p>
                </div>

                <div class='goal-info'
                    v-if='hovered !== null'
                >
                    <img class='goal-logo' :src='hovered.image' v-if="hovered.image && hovered.image !== null" />

                    <h3 class='number'>{{ this.getLabel( hovered ) }}</h3>
                    <p class='label' v-html='hovered.label_long'></p>
                </div>

                <svg class='matrix-legend'
                    width="90%"
                    height="250px"
                >
                    <score-legend
                        :height="250"
                        :width="250"
                        :values="[
                            {value: 3, label : '3'},
                            {value: 2, label : '2'},
                            {value: 1, label : '1'},
                            {value: 0, label : '0'},
                            {value: -1, label : '-1'},
                            {value: -2, label : '-2'},
                            {value: -3, label : '-3'}
                        ]"
                        :color-scale='colorScale'
                        :fixed-circle-size='true'
                    ></score-legend>

                </svg>
            </div>
            <matrix
                :width="900"
                :interactions="goals"
                :answers="m_answers"
                :event="event"
                :restrictions="restrictions"
                :answerOptions="answerOptions"
                :margin="{
                     top: 80,
                        left: 80,
                        right: 80,
                        bottom: 80
                    }"
                @goal-hover='handleGoalHover'
            >
                <template v-slot:modal="{
                        answering, 
                        event,
                        closeModal
                    }">
                    <answering-modal
                        :answering='answering'
                        :event='event'
                        @added='( a ) => {
                            handleAnswerAdd( a )
                            closeModal()
                        }'
			    	    @removed='( a ) => {
                            handleAnswerDelete( a )
                            closeModal()
                        }'
                        @goal-hover='handleGoalHover'
                        @close='closeModal'
                    ></answering-modal>
                </template>
            </matrix>
        </div>
    </div>
</template>

<script>
import { scaleQuantize } from 'd3-scale';
import { scaleColors, getLabel } from './../../lib/common';
import MobileMatrix from './Mobile/MobileMatrix.vue';
import AnsweringModal from './AnsweringModal.vue';
import FloatingSettings from './../FloatingSettings.vue';

export default {
    components : {
        'matrix' : require("./Matrix.vue").default,
        'score-legend': require("./../ScoreLegend.vue").default,
        AnsweringModal,
        MobileMatrix,
        FloatingSettings
    },
    data: function(){
        return {
            hovered: null,
            goalImages: [],
            m_answers: this.answers
        }
    },
    props :{
        goals: {
            type: Array,
            default: () => {
                return []
            }
        },
        answers: {
            type: Array,
            default: () => {
                return []
            }
        },
        restrictions: {
            type: Array,
            default: () => {
                return []
            }
        },
        event: {
            type: Number,
            default: 1,
        },
        answerOptions: {
            type: Array,
            default: () => {
                return [-3, -2, -1, 0, 1, 2, 1]
            }
        },
        logo: {
            type: String,
            default: null
        },
        helpText: {
            type: String,
            default: null
        }
    },
    computed: {
        colorScale(){
            return scaleQuantize()
                .domain([-3.49999, 3.49999])
                .range( scaleColors )
        }
    },
    methods: {
        handleGoalHover( goal ){
            this.hovered = goal
        },
        handleAnswerAdd( newAnswer ){
			let idx = this.m_answers.findIndex( a => a.id === newAnswer.id )

			if( idx > -1 ){
				this.m_answers.splice( idx, 1, newAnswer ) 
			} else {				
				this.m_answers.push( newAnswer );
			}
		},
		handleAnswerDelete( oldAnswerId ){
			let idx = this.m_answers.findIndex( a => a.id === oldAnswerId )

			if( idx > -1 ){
				this.m_answers.splice( idx, 1 );
			}
        },
        getLabel( goal ){
            return getLabel( goal, {number: 'number',  label: 'label_short' } )
        }
    },
    beforeMount(){
        const loc = this.$i18n.locale

        let images = []

        for(let i = 1; i <= 17; i++){
            images.push("/images/goals/" + loc + "/" + i + ".png")
        }

        function imageLoadCallback( evt ){
            this.goalImages.push(evt.path[0])
        }

        function preloadImage( url, callback ){
            var img = new Image()
            img.src = url

            if(callback && typeof callback === "function"){
                img.onload = callback
            }

        }
        
        function preloadImages( urls, callback ){
            urls.forEach( (url) => {
                preloadImage( url, callback )
            })
        }

        preloadImages( images, imageLoadCallback.bind(this) );


    }
}
</script>

<style lang='scss' scoped>
.goal-info{
    .number{
        margin-left: 70px;
        width: calc( 100% - 70px );
        text-align: left;
    }
}
</style>