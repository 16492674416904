<template>
    <g class='matrix-grid-lines'>
        <g class='row-grid'>
            <line v-for='row in rowScale.domain()'
                :key='row'
                class='grid-line row'
                :x1="colScale.range()[0]"
                :x2="colScale.range()[1]"
                :y1="rowScale(row)"
                :y2="rowScale(row)"
            ></line>
        </g>
        <g class='col-grid'>
            <line v-for='col in colScale.domain()'
                :key='col'
                class='grid-line col'
                :x1="colScale(col)"
                :x2="colScale(col)"
                :y1="rowScale.range()[0]"
                :y2="rowScale.range()[1]"
            ></line>
        </g>
        <path v-for='row in rowScale.domain()'
            :key='row'
            :d='emptyPath(colScale(row), rowScale(row))'
            class='null-answer'
        ></path>
    </g>
</template>

<script>
export default {
    props: {
        rowScale: {
            type: Function,
            required: true
        },
        colScale : {
            type: Function,
            required: true
        }
    },
    methods: {
        emptyPath( x, y ){
            let first = "M" + (x-4) + " " + (y-4) + " l8 8"
            let second = "M" + (x-4) + " " + (y+4) + " l8 -8"

            return [first, second].join(" ")
        }
    }
}
</script>

<style>

</style>