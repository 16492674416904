<template>
	<div class='goal-card' ref='container'>
		<div class='header'>
			<img :src='goal.image' :alt='"goal " + goal.number' />
			<h3>
				{{ label }}
			</h3>
		</div>

		<div class='toggle-buttons'>
			<a v-if='descriptionVisible' @click='toggleDescription' class="toggle description">
				<i class='fas fa-info-circle'></i>
				{{ $t( 'matrix.hide description' ) }}
			</a>
			<a v-else @click='toggleDescription' class="toggle description">
				<i class='fas fa-info-circle'></i>
				{{ $t( 'matrix.full description' ) }}
			</a>
			<a v-if='interactionsVisible' @click='toggleInteractions' class="toggle interactions">
				<i class='fas fa-caret-square-up'></i>
				{{ $t( 'matrix.hide interactions') }}
			</a>
			<a v-else @click='toggleInteractions' class="toggle interactions">
				<i class='fas fa-caret-square-down'></i>
				{{ $t( 'matrix.score interactions') }}
			</a>
		</div>

		<Collapsible class='description-box' :open='descriptionVisible'>
			<div class="contained-box">
				<p>{{ goal.label_long }}</p>
			</div>
		</Collapsible>
		
		<Collapsible :open='interactionsVisible'>
			<div class='interaction-list'>
				<slot name='interactions'></slot>
			</div>
		</Collapsible>

		<slot name='overlay' 
			:showIndicator='showIndicator' 
			:interactionsVisible='interactionsVisible'
			:toggleInteractions='toggleInteractions'
		>
		</slot>
	</div>
</template>

<script>
import Collapsible from './../../shnt-vue-tabs/src/components/Collapsible.vue';

export default {
	components: {
		Collapsible,
	},
	data: function(){
		return {
			descriptionVisible: false,
			interactionsVisible: false,
			showIndicator: false
		}
	},
	props: {
		goal: {
			type: Object,
			required: true
		}
	},
	computed: {
		label(){
			if(!this.goal.label_short || this.goal.label_short === ""){
				return this.goal.number
			}
			return [this.goal.number, this.goal.label_short].join( " - ")
		}
	},
	methods: {
		toggleDescription(){
			this.descriptionVisible = !this.descriptionVisible
		},
		toggleInteractions(){
			this.interactionsVisible = !this.interactionsVisible
		},
		locateContainer(){
			if( !this.interactionsVisible ){
				this.showIndicator = false;
				return
			}
			let containerRect = this.$refs.container.getBoundingClientRect()

			if( containerRect.top < -150 && containerRect.bottom > 150 ){
				this.showIndicator = true
			} else {
				this.showIndicator = false
			}
		}
	},
	mounted(){
		window.addEventListener( "scroll", this.locateContainer.bind( this ) );
	},
	destroyed(){
		window.removeEventListener( "scroll", this.locateContainer.bind( this ) );
	}
}
</script>

<style lang='scss' scoped>
.goal-card{
	width: 90%;
	min-width: 300px;
	max-width: 500px;
	margin-left: auto;
	margin-right: auto;
	background-color: #FBFBFB;
	border: solid 1px #E5E5E5;
	border-radius: 5px;
	padding: 10px 10px 0;
	position: relative;

	.header{
		width: 100%;
		display: flex;
		flex-wrap: nowrap;
		margin-bottom: 10px;
		
		img{
			width: 80px;
			height: 80px;
			border-radius: 5px;
			border: solid 1px #E5E5E5;
		}

		h3{
			color: #263238;
			margin: 0 0 5px 10px;
			width: calc( 100% - 95px );
			font-size: 16px;
		}
	}

	.toggle-buttons{
		margin: 5px 0;
		padding: 5px 0;
		width: 100%;
		border-top: 1px solid #E5E5E5;
		overflow: auto;

		.toggle{
			
			cursor: pointer;
			font-size: 13px;
			

			&.description{
				color: #1A3C6A;
				float: left;
			}

			&.interactions{
				color: #00B584;
				float: right;
				
			}
		}
	}

	.contained-box{
		width: 100%;
		overflow: hidden;

		p{
			margin: 0 0 10px;
		}
	}
}
</style>